import { useState, useEffect } from "react";
import './homepage.css';
import Navbar from '../navbar/navbar';
import About from '../about/about';
import Contact from '../contact/contact';
import Meeting from '../meeting/meeting';
import Summary from '../summary/summary';
import Welcome from "../Welcome/welcome";

export function Homepage() {
  const [media, setMedia] = useState('');
  const [view, setView] = useState(0);

  useEffect(() => {
    const mediaQueries = [
      { size: 'reg', query: window.matchMedia("only screen and (min-width: 768px) ") },
      { size: 'sm', query: window.matchMedia("only screen and (max-width: 768px)") }
    ];

    const match = mediaQueries.find(entry => entry.query.matches);
    setMedia(match.size);

    function handleChange(e) {
      const update = mediaQueries.find(entry => entry.query.matches);
      setMedia(update.size);
    }
    match.query.addEventListener("change", handleChange);
    return () => {
      match.query.removeEventListener("change", handleChange);
    };
  }, [media]);

  return (
    <>
      <Navbar size={media} setView={setView} />
      {view === 0 ? <Welcome size={media} /> :
        <div className='homepage'>
          {view === 1 ? <Summary size={media} />:
          view === 2 ? <Contact size={media} />:
          view === 3 ? <About size={media} />:
          <Meeting size={media} />
          }

          {<div className="footer">
            <div className="footer-box">
              <p>Designed by <a href="https://awc-home.vercel.app/">AWC</a></p>
            </div>

          </div>}
        </div>

      }


    </>

  );
}