import "./summary.css";
import photo1 from "../Assets/Coaches.jpg"
import photo2 from "../Assets/0K5A6543-Edit.jpg"
import photo3 from "../Assets/group.jpg"



export default function Summary(props) {
    return (
        <div>
            <div className="abtUs">
                <div className="abtPhotos">
                    <img src={photo2} alt="photo" />
                    <img src={photo1} alt="photo" />
                    <img src={photo3} alt="photo" />
                </div>
                <h2>Meet Our Coaches:</h2>
                <div className="bios">
                    <h3>Chris Kukosky</h3>
                    <p>Chris’s journey through athletics began at Lake Lehman where from 1982-86, he left a mark on the sports scene. Kukosky became the first freshman to letter for the Lake Lehman volleyball program. The team clinched three District 2 titles and secured a runner up position under his leadership.
                        In 2018, he returned to the sport as a coach, leading his team to the AAA district semifinal match in his inaugural season. Under his mentorship, Lake-Lehman has earned District Title in 2022, 18 girls earning spots on various all-star teams, two players on AA all-state team in 2022, followed by another standout player in 2023.
                        Chris Kukosky’s journey from high school sports star to a revered coach exemplifies the enduring impact of dedication, skill, and love for the game.
                    </p>
                    <h3>Mark Major</h3>
                    <p>Mark began his multi-sport athletic career at Lake Lehman covering baseball, volleyball, wrestling and football. During this time Mark was part of the 3 Time, District 2 Volleyball Team. He continued to play volleyball at the local collegiate level.
                        Upon graduation with his BS in Health and Physical Education Mark taught for several years while co-developing a business that became the International Sports Training Camp (ISTC) and Trout Lake Retreats (TL). Both ISTC and TL quickly became the benchmark for summer sports program and is now known throughout the world. Mark has trained, taught, coached and positively impacted thousands of people from ages 8-25+ during his 30-year directorship.
                        During this time, Mark was seen as a leader in his industry. Now that Mark is retired, he has been sharing his time and experience with the Lake Lehman Boys, and Girls volleyball teams for the past 7 years, paying it forward and helping young athletes to develop to their highest potential.

                    </p>
                    <h3>Miranda Parry</h3>
                    <p>Miranda’s love of sports began at a young age, where she eagerly engaged in four different sports throughout her childhood and high school years. Her passion for athletics propelled her to excel in each discipline, earning her varsity letters across the board during her high school tenure. Notably, Miranda served as the indomitable captain and libero of her high school volleyball team, demonstrating both leadership and exceptional skill on the court.
                        Beyond her achievements on the field, Miranda embarked on a career path dedicated to enhancing physical wellness. She currently thrives as a physical therapist at Pro Rehab, leveraging her expertise to aid patients in their rehabilitation journeys. Miranda earned her Doctorate in Physical Therapy from Misericordia in 2022, preceded by her Bachelor's degree in Sport Management in 2019 from the same institution.
                        Miranda's dedication extends beyond treatment rooms and sports fields; she finds fulfillment in teaching and is deeply committed to improving her players' and patients' quality of life. Her holistic approach to healthcare and sport coaching reflects her unwavering passion for helping others excel in both health and athleticism.

                    </p>
                    <h3>Brynn Kukosky</h3>
                    <p>Brynn is a dedicated individual with a passion for sports and education. Brynn began her journey at Holy Redeemer High School, where she served as team captain and was honored as the Citizens Voice 2016 Player of the Year. Brynn was also named All-State in volleyball in 2016.
                        Continuing her academic and athletic pursuits, Brynn attended Susquehanna University from 2017 to 2021, where she was actively involved as a Student-Athlete Advisory Committee (SAAC) member, eventually serving as Vice President during her senior year. As a student athlete, Brynn captained the team in her sophomore year and her team participated in the 2019 NCAA Tournament. Recognized for sportsmanship, she was named to the 2019 Landmark All Sportsmanship Team.
                        After graduation, Brynn transitioned to Misericordia University, where she expanded her role as Assistant Coach for both men's and women's teams from 2021 to 2023. In this role, she also served as the recruiting coordinator, helping to shape the future of the program. Brynn is extremely passionate about mentoring young athletes and contributing to their success.
                    </p>
                    <h3>Brynn Giordano</h3>
                    <p>Brynn Giordano began her volleyball journey as a freshman in high school, playing as a libero/defensive specialist. Throughout her high school career at Lake Lehman, under the guidance of Coach Chris, she was part of the 2023 team that clinched the District Championship Title. Her accolades include earning the title of Defensive Player of the Year, MVP, obtaining 500 digs in the Wyoming Valley Conference and being named All State Libero in 2023.
                        Currently, she is pursuing her academics and continuing her volleyball career at King's College. In the off-season, Brynn serves as an assistant coach at Lake Lehman, sharing her experience and skills with aspiring players. Her dedication to the sport and her team has made her a respected and influential figure in the volleyball community.
                    </p>
                    <h3>A Word From Coach Kachinko of King’s College</h3>
                    <p>
                        I have had the opportunity to know Coach Kukosky for over 25 years.  He was one the top players in the District 2 Wyoming Valley Conference during his playing days.  His Lake Lehman team was not only a District 2 power but also one of the best in the state.  He was coached by one of all-time best in the state in John Baranowski.  It is great to see that he has been giving back to his alma mater and developing Lake Lehman into a consistent winner for years now and starting Club Vollocity.  I am a very big fan of area volleyball.  I really like that there is another club where kids from the area have a chance to join and develop their skill sets. I have had the great honor to now be coaching one of his former players and a member of his coaching staff Brynn Giordano.
                        Also, I have had the opportunity to have a number of his players at our King’s College girls’ volleyball camp these past years.  They all have been a joy to coach.  I can really see the passion they have for the game with a very strong desire to learn and excel.  I see they are eager to take on challenges on the court.  They are very coachable and have been outstanding teammates. I see how they put their heart and soul when they are out on the court.  It is great to see they are so skilled with their fundamentals and volleyball IQ.  It is a great testament to Coach Kukosky passing on that knowledge of the game.  He is the driving force on why I see his Lake Lehman and Club Vollocity elevating to a top-level program.
                        It is very nice to see that these programs are in great hands moving forward and I look forward to coaching Brynn and having many more of his high school and club team at camp.   Congrats on what you have accomplished so far and look forward to where your programs get to next.  I know wholeheartedly that Club Vollocity is going to make an immediate impact.  Thanks again to your entire staff for giving kids a chance to play this great game of volleyball we have loved for decades.  Continued success.

                    </p>
                </div>
            </div>
        </div>
    );

}
