import { useState } from "react";
import "./navbar.css";
import Hamburger from "hamburger-react";
import photo from "../Assets/ClubVollocity_Logo.png"

export default function Navbar(props) {
  const [isOpen, setOpen] = useState(false);

  function scrollTo(id) {
    const eleID = document.getElementById(id);
    window.scroll({
      top: eleID.offsetTop,
      behavior: "smooth",
    });
  }

  if (props.size === "sm")
    return (
      <div className="navbar sm">
        <img src={ photo } alt="photo" />
        <div className="tabbar sm">
          <div className="tabbuttons sm">
            <Hamburger toggled={ isOpen } toggle={ setOpen } />
            { isOpen ? (
              <>
                <button onClick={ (e) => {
                  props.setView(0);
                } }>Home</button>
                <button onClick={ (e) => {
                  props.setView(1);
                } }>About</button>
                <button onClick={ (e) => {
                  props.setView(2);
                } }>Our Team</button>
                <button> <a href="https://clubvollocity.itemorder.com/shop/closed/" target="blank">Store</a></button>
                <button onClick={ (e) => {
                  props.setView(3);
                } } //</>style={ { borderRight: "2px solid #0E203D" } }
                >Resources</button>
              </>
            ) : null }
          </div>
        </div>
      </div>
    );

  else return (
    <div className="navbar">
      <img src={ photo } alt="photo" />
      <div className="tabbar">
        <div className="tabbuttons">
          <button onClick={ (e) => {
            props.setView(0);
          } }>Home</button>
          <button onClick={ (e) => {
            props.setView(1);
          } }>About</button>
          <button onClick={ (e) => {
            props.setView(2);
          } }>Our Team</button>
          <button> <a href="https://clubvollocity.itemorder.com/shop/closed/" target="blank">Store</a></button>
          <button onClick={ (e) => {
            props.setView(3);
          } } style={ { borderRight: "2px solid #0E203D" } }>Resources</button>
        </div>
      </div>
    </div>
  );
}
