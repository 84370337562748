import { useState } from "react";
import About from "../about/about";
import "./welcome.css"
import bg from "../Assets/ClubVollocity_Logo.png"
import { FaFacebookF, FaInstagram } from "react-icons/fa"


export default function Welcome(props) {
    const [isOpen, setOpen] = useState(false);

    return (
        <div className="welcomeFormat">
            <div className="summary">
                    <h2>Welcome to Club Vollocity</h2>
                    <p>Our mission is to provide a safe environment conducive to learning both the fundamentals, and latest techniques, in women’s volleyball.</p>
                    <p>Our experienced team of coaches will guide your players teaching technique, skill-based volleyball, while emphasizing sportsmanship and teamwork. Our goal is to build a strong foundation for all future growth.</p>
                </div>
            <div className="Welcomediv">
            
                <button className="SignButton"> <a href="https://docs.google.com/document/d/1GPO4btItWiLqf81pjusdje7SmWxB1adX/edit?usp=sharing&ouid=105499710533681496050&rtpof=true&sd=true" target="blank"> Sign-Up Now! </a> </button>
                <button className="SignButton"><a href="https://www.facebook.com/profile.php?id=61560189098358" target="blank"> <FaFacebookF /> </a></button>
                <button className="SignButton"><a href="https://www.instagram.com/clubvollocity/" target="blank"> <FaInstagram /> </a></button>
            
            <img src={bg} alt="photo" />
            </div>
        </div>
    )
}